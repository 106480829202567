<template>
  <div>
    <div class="my-account-back-to-dashboard">
      <SvgImage
        class="my-account-back-to-dashboard__icon"
        height="10"
        icon="Icon_fleche_gauche"
        width="10"
      />
      <SfLink
        :link="localeRoute({ name: 'customer-dashboard' })"
        class="my-account-back-to-dashboard__text"
      >
        Mon tableau de bord
      </SfLink>
    </div>

    <div class="my-account-heading">
      <h2 class="my-account-heading__title">
        Mes sous-comptes
      </h2>
    </div>

    <div v-if="isSuccess === 'true'" class="sub-account__msg-banner sub-account__msg-banner--success">
      <SvgImage
        class="error__icon"
        height="20"
        icon="Icon_validate_dark"
        width="24"
      />
      <span>Vos changements ont bien été pris en compte et votre sous-compte a été notifié par email.</span>
    </div>

    <div class="sub-account">
      <div class="sub-account__header">
        <p>{{ description }}</p>
      </div>
      <div v-if="subAccounts && subAccounts.length" class="sub-account__list">
        <div class="sub-account__list-row sub-account__list-row--header">
          <div class="sub-account__list-title sub-account__list-title--name">Nom</div>
          <div class="sub-account__list-title sub-account__list-title--email">E-mail</div>
          <div class="sub-account__list-title sub-account__list-title--phone">Téléphone</div>
          <div class="sub-account__list-title sub-account__list-title--status">Statut</div>
          <div class="sub-account__list-title sub-account__list-title--actions"></div>
        </div>

        <template v-for="subAccount in subAccounts">
          <div class="sub-account__list-row sub-account__list-row--content">
            <div class="sub-account__list-content sub-account__list-content--name">{{ subAccount.lastname }}
              {{ subAccount.firstname }}
            </div>
            <div class="sub-account__list-content sub-account__list-content--email">{{ subAccount.email }}</div>
            <div class="sub-account__list-content sub-account__list-content--phone">{{ subAccount.telephone }}</div>
            <div :class="{ 'sub-account__list-content--status-actif': subAccount.role.active === 1 }"
                 class="sub-account__list-content sub-account__list-content--status">
              {{ subAccount.role.active === 1 ? 'Actif' : 'Inactif' }}
            </div>
            <div class="sub-account__list-content sub-account__list-content--actions">
              <SfLink :link="localeRoute({ name: 'customer-update-sub-account', params: {subAccountId: '' + subAccount.role.customer_id} })"
                      class="sf-button sub-account__modify-button sf-button__secondary"
              >
                Modifier
              </SfLink>
              <a v-if="!isCommercial" class="sub-account__delete-button"
                 @click.prevent="onDeleteShowPopin(subAccount.role.entity_id)">
                Supprimer
              </a>
            </div>
          </div>
        </template>
      </div>

      <SfLink
        v-if="!isCommercial"
        :link="localeRoute({ name: 'customer-create-sub-accounts' })"
        class="sf-button sub-account__create-button sf-button__primary"
      >
        Ajouter un sous-compte
      </SfLink>
    </div>
  </div>
</template>

<script>
import { SfButton, SfLink, SfModal } from '@storefront-ui/vue';
import SvgImage from '~/components/General/SvgImage.vue';
import { ref, useFetch } from '@nuxtjs/composition-api';
import { useConfig, useSubAccount, useUiState, useUser } from '~/composables';
import getURLParams from "~/helpers/utils/getURLParams";
import { cacheRole } from "~/helpers/customer/role";
import DeleteSubAccountModal from "~/modules/customer/pages/MyAccount/SubAccount/DeleteSubAccountModal.vue";
import { useCustomerStore } from '~/modules/customer/stores/customer';

export default {
  components: {
    DeleteSubAccountModal,
    SfLink,
    SvgImage,
    SfModal,
    SfButton
  },
  setup() {
    const { config }                                     = useConfig();
    const { load: loadUser }                             = useUser();
    const { getList: getListSubAccounts, remove }        = useSubAccount();
    const { isDeleteSubModalOpen, toggleDeleteSubModal } = useUiState();
    const isSuccess                                      = getURLParams('isSuccess');
    const { isCommercial }                               = cacheRole();
    const customerStore                                  = useCustomerStore();

    // Récupération des sous-comptes
    let subAccounts = ref([]);
    useFetch(async () => {
      const userResult        = await loadUser({ customQuery: { customer: 'customCustomer' } });
      const subAccountsResult = await getListSubAccounts(userResult.role.cod_client);
      subAccounts.value       = subAccountsResult.data ? subAccountsResult.data.getListSubAccount : [];
    });

    // Récupération configuration Magento
    let description          = ref('');
    const messages           = config.value?.subaccount_messages || [];
    const messageDescription = messages.find((message) => message.key === 'message_description');
    description.value        = messageDescription?.value;

    let subAccountIdToDelete = ref(0);

    // Méthode affichage popin confirmation suppression des sous-comptes (Dans MyAccount.vue)
    const onDeleteShowPopin = async (roleEntityId) => {
      customerStore.setSubAccountIdToDelete(roleEntityId);
      toggleDeleteSubModal();
    };
    // Méthode suppression des sous-comptes
    const onDelete          = async () => {
      await remove(subAccountIdToDelete.value);
      toggleDeleteSubModal();
      subAccounts.value = subAccounts.value.filter((subAccount) => subAccount.role.entity_id !== subAccountIdToDelete.value)
    };

    return {
      subAccounts,
      description,
      isDeleteSubModalOpen,
      toggleDeleteSubModal,
      onDeleteShowPopin,
      onDelete,
      isSuccess,
      isCommercial
    }
  }
}
</script>

<style lang='scss'>
@import '@/modules/customer/pages/styles/shared.scss';
@import '@/modules/customer/pages/styles/sub-account.scss';
</style>
