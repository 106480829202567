import { render, staticRenderFns } from "./DeleteSubAccountModal.vue?vue&type=template&id=48183314&scoped=true"
import script from "./DeleteSubAccountModal.vue?vue&type=script&lang=js"
export * from "./DeleteSubAccountModal.vue?vue&type=script&lang=js"
import style0 from "./DeleteSubAccountModal.vue?vue&type=style&index=0&id=48183314&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48183314",
  null
  
)

export default component.exports