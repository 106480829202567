<template>
  <div class="modalApproval">
    <SfModal
      :cross="true"
      :visible="isDeleteSubModalOpen"
      @close="closeModal"
    >
      <div>
        <p class="title">Confirmation de suppression de sous-compte</p>
      </div>

      <div class="bloc-approval">
        <p class="question">Êtes-vous sûr(e) de vouloir supprimer ce sous-compte ?</p>
        <div class="bloc-button">
          <SfButton
            :aria-disabled="false"
            class="sf-button sf-button__secondary button"
            @click="toggleDeleteSubModal"
          >
            Annuler
          </SfButton>
          <SfButton
            class="sf-button sf-button__primary button"
            @click.prevent="onDelete"
          >
            Confirmer
          </SfButton>
        </div>
      </div>
    </SfModal>
  </div>
</template>
<script>
import { SfButton, SfModal } from '@storefront-ui/vue';
import { useUiState } from '~/composables';
import HTMLContent from "@/components/HTMLContent";

export default {
  name: 'DeleteSubAccountModal',
  components: {
    HTMLContent,
    SfModal,
    SfButton
  },
  setup(props, { emit }) {
    const { isDeleteSubModalOpen, toggleDeleteSubModal } = useUiState();

    const closeModal = () => {
      toggleDeleteSubModal();
    };

    const onDelete = () => {
      emit('onDelete')
    }

    return {
      closeModal,
      isDeleteSubModalOpen,
      toggleDeleteSubModal,
      onDelete
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/theme/default/scss/components/molecules/cart-approval-modal.scss';
</style>
